var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',[_c('v-form',{ref:"form",attrs:{"lazy-validation":""}},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12","sm":"12"}},[_c('v-text-field',{attrs:{"label":"Titulo","maxlength":"50","counter":50,"autocomplete":"off","outlined":"","placeholder":"Ej. Mecánica","rules":[
              function (v) { return !!v || 'Titulo es requerido'; },
              function (v) { return (v && v.length >= 5 && v.length <= 50) ||
                'el titulo debe tener entre 5 y 50 caracteres'; } ]},model:{value:(_vm.nuevoServicio.titulo),callback:function ($$v) {_vm.$set(_vm.nuevoServicio, "titulo", $$v)},expression:"nuevoServicio.titulo"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"12"}},[_c('v-text-field',{attrs:{"label":"Descripción","maxlength":"50","counter":50,"autocomplete":"off","outlined":"","placeholder":"Ej. Servicios de reparaciones de motor y otros","rules":[
              function (v) { return !!v || 'Descripción es requerido'; },
              function (v) { return (v && v.length >= 5 && v.length <= 50) ||
                'La Descripción debe tener entre 5 y 50 caracteres'; } ]},model:{value:(_vm.nuevoServicio.descripcion),callback:function ($$v) {_vm.$set(_vm.nuevoServicio, "descripcion", $$v)},expression:"nuevoServicio.descripcion"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"12"}},[_c('v-textarea',{attrs:{"outlined":"","label":"Detalle de Servicios","placeholder":"Ej. Se realizan cambios de motor, caja, etc.","rules":[
              function (v) { return !!v || 'Detalle es requerido'; },
              function (v) { return (v && v.length >= 5 && v.length <= 200) ||
                'Detalle debe tener entre 5 y 200 caracteres'; } ]},model:{value:(_vm.nuevoServicio.detalle),callback:function ($$v) {_vm.$set(_vm.nuevoServicio, "detalle", $$v)},expression:"nuevoServicio.detalle"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"12"}},[_c('v-text-field',{attrs:{"label":"Numero de pasos para concluir con el servicio","maxlength":"30","counter":30,"autocomplete":"off","outlined":"","type":"number","rules":[
              function (v) { return !!v || 'Los pasos son requeridos'; },
              function (v) { return (v && v >= 1 && v <= 10) ||
                'Los pasos deben ser de 1 y 10'; } ]},model:{value:(_vm.nuevoServicio.pasos),callback:function ($$v) {_vm.$set(_vm.nuevoServicio, "pasos", $$v)},expression:"nuevoServicio.pasos"}})],1)],1)],1),_c('v-btn',{staticClass:"pl-4 pr-4",attrs:{"type":"submit","color":"primary","disabled":_vm.cargando},on:{"click":_vm.agregarServicio}},[_vm._v("Guardar "),(_vm.cargando)?_c('v-progress-circular',{attrs:{"indeterminate":"","color":"primary"}}):_vm._e()],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }