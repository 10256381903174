<template>
  <div>
    <DContenidoPagina :titulo="'Programacion de calendario de: ' + item.titulo">
      <v-row>
        <v-col cols="12" md="6">
          <!-- <div class="text-title text--primary"> Programar una fecha para el servicio</div> -->
          <v-form ref="form1" lazy-validation>
            <v-container>
              <v-row>
                <v-col cols="12" sm="6">
                  <v-text-field
                    label="Tipo"
                    v-model="nuevoCalendario.tipo"
                    outlined
                    disabled
                    :rules="[
                      (v) => !!v || 'tipo es requerido',
                      (v) =>
                        (v && v.length >= 5 && v.length <= 100) ||
                        'Descripcion debe tener entre 5 y 100 caracteres',
                    ]"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-text-field
                    type="date"
                    label="Fecha a programar"
                    v-model="nuevoCalendario.fecha"
                    outlined
                    :rules="[
                      (v) => !!v || 'fecha es requerido',
                      (v) =>
                        (v && v.length >= 5 && v.length <= 100) ||
                        'fecha debe tener mayor a 5 caracteres',
                    ]"
                  ></v-text-field>
                </v-col>

                <v-col cols="12">
                  <v-textarea
                    outlined
                    v-model="nuevoCalendario.descripcion"
                    label="Descripcion del espacio disponible"
                    placeholder="Ej. Espacio disponible express de dos horas"
                    :rules="[
                      (v) => !!v || 'Descripcion es requerido',
                      (v) =>
                        (v && v.length >= 5 && v.length <= 400) ||
                        'Descripcion debe tener entre 5 y 400 caracteres',
                    ]"
                  ></v-textarea>
                </v-col>
              </v-row>
              <v-row>
                <v-btn
                  class="ma-2"
                  :loading="cargando"
                  :disabled="cargando"
                  color="primary"
                  @click="agregarCupo()"
                >
                  Programar Espacio 
                </v-btn>
              </v-row>
            </v-container>
          </v-form>
        </v-col>
        <v-col cols="12" md="6">
          <div>
            <v-list>
              <h4 >Lista de programación de Espacios</h4>
              <template v-for="itemc in calendario.slice().reverse()">
                <div :key="itemc.key">
                  <v-list-item>
                    <v-list-item-avatar>
                      <v-icon class="blue" dark small>mdi-calendar</v-icon>
                    </v-list-item-avatar>

                    <v-list-item-content>
                      <v-list-item-title>
                        {{ itemc.descripcion }}
                        <v-chip
                          :color="itemc.estado ? `orange` : `green`"
                          small
                          dark
                        >
                          {{ itemc.estado ? "Reservado" : "Libre" }}
                        </v-chip>
                      </v-list-item-title>

                      <v-list-item-subtitle
                        v-text="itemc.fecha"
                      ></v-list-item-subtitle>
                    </v-list-item-content>

                    <v-list-item-action>
                      <v-btn
                        x-small
                        outlined
                        color="red"
                        @click="eliminar(itemc)"
                      >
                        <v-icon x-small left dark> mdi-delete </v-icon>
                        Eliminar
                      </v-btn>
                    </v-list-item-action>
                  </v-list-item>
                  <v-divider inset></v-divider>
                </div>
              </template>
            </v-list>
          </div>
        </v-col>
      </v-row>
    </DContenidoPagina>
    <div>
      <v-dialog v-model="dialogEliminar" persistent max-width="290">
        <v-card>
          <v-card-title class="text-h5"> Eliminar Cupo </v-card-title>
          <v-card-text
            >El cupo se eliminara de forma permanente de la lista</v-card-text
          >
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="green darken-1" text @click="dialogEliminar = false">
              Cancelar
            </v-btn>
            <v-btn color="green darken-1" text @click="eliminarRegistro()">
              Eliminar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>

<script>
import { DContenidoPagina } from "../../../components";
import app from "../../../firebase";
import {
  getDatabase,
  ref,
  set,
  push,
  remove,
  onChildChanged,
  onChildAdded,
  onChildRemoved,
} from "firebase/database";

export default {
  components: {
    DContenidoPagina,
  },
  props: ["item"],
  data() {
    return {
      dialogoC: false,
      dialogEliminar: false,
      esNuevo: true,
      calendario: [],
      cargando: false,
      itemSelect: {},
      nuevoCalendario: {
        fecha: "",
        tipo: "",
        descripcion: "",
        pasos: 0,
        estado: false,
      },
    };
  },
  mounted() {
    const db = getDatabase(app);
    const commentsRef = ref(db, "calendario/" + this.item.key);
    this.nuevoCalendario.tipo = this.item.titulo;
    this.nuevoCalendario.pasos = this.item.pasos;
    onChildAdded(commentsRef, (data) => {
      this.calendario.push({
        key: data.key,
        fecha: data.val().fecha,
        tipo: data.val().tipo,
        descripcion: data.val().descripcion,
        estado: data.val().estado,
      });
    });
    onChildChanged(commentsRef, (data) => {
      for (let i = 0; i < this.calendario.length; i++) {
        if (this.calendario[i].key == data.key) {
          this.calendario[i].fecha = data.val().fecha;
          this.calendario[i].tipo = data.val().tipo;
          this.calendario[i].descripcion = data.val().descripcion;
          this.calendario[i].estado = data.val().estado;
        }
      }
    });
    onChildRemoved(commentsRef, (data) => {
      for (let i = 0; i < this.calendario.length; i++) {
        if (this.calendario[i].key == data.key) {
          this.calendario.splice(i, 1);
        }
      }
    });
  },
  methods: {
    agregarCupo() {
      if (!this.$refs.form1.validate()) {
        return;
      }
      const db = getDatabase(app);
      this.cargando = true;
      const postListRef = ref(db, "calendario/" + this.item.key);
      const newPostRef = push(postListRef);
      set(newPostRef, this.nuevoCalendario);
      this.cargando = false;
      this.$store.commit("alert", {
        color: "success",
        text: "Se programo el espacio disponible de forma correcta",
      });
      this.nuevoCalendario.fecha="";
      this.nuevoCalendario.descripcion="";
    },
    eliminar(item) {
      this.itemSelect = item;
      this.dialogEliminar = true;
    },
    eliminarRegistro() {
      const db = getDatabase(app);
      remove(
        ref(db, "calendario/" + this.item.key + "/" + this.itemSelect.key)
      );
      this.dialogEliminar = false;
      this.$store.commit("alert", {
        color: "success",
        text: "Se elimino de forma correcta",
      });
    },
  },
};
</script>

<style></style>
