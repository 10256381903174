<template>
  <div>
    <DContenidoPagina titulo="Administración de servicios">
      <DBotonSimple
        texto="Agregar Servicio"
        @click="crearServicio()"
        class="d-flex flex-row-reverse"
      />
      <div>
        <v-list three-line>
          <template v-for="(item, index) in servicios">
            <v-card :key="index" class="mx-auto mt-1">
              <v-card-text>
                <v-row>
                  <v-col cols="4">
                    <div>
                      <v-img
                        v-if="item.titulo.toLowerCase().includes('carrocería')"
                        width="150px"
                        src="~@/assets/img/servicios/carroceria.png"
                      ></v-img>
                      <v-img
                        v-else-if="item.titulo.toLowerCase().includes('pintura')"
                        width="150px"
                        src="~@/assets/img/servicios/pintura.png"
                      ></v-img>
                      <v-img
                        v-else-if="item.titulo.toLowerCase().includes('preventivo')"
                        width="150px"
                        src="~@/assets/img/servicios/preventivo.png"
                      ></v-img>
                      <v-img
                        v-else-if="item.titulo.toLowerCase().includes('express')"
                        width="150px"
                        src="~@/assets/img/servicios/express.png"
                      ></v-img>
                      <v-img
                        v-else-if="item.titulo.toLowerCase().includes('renault')"
                        width="150px"
                        src="~@/assets/img/servicios/renault.png"
                      ></v-img>
                      <v-img
                        v-else-if="item.titulo.toLowerCase().includes('automáticas')"
                        width="150px"
                        src="~@/assets/img/servicios/automaticas.png"
                      ></v-img>
                      <v-img
                        v-else
                        width="150px"
                        src="~@/assets/img/servicios/preventivo.png"
                      ></v-img>
                    </div>
                  </v-col>
                  <v-col cols="8">
                    <div>Pasos: {{ item.pasos }}</div>

                    <p class="text-h5 text--primary">{{ item.titulo }}</p>
                    <p>Descripción: {{ item.descripcion }}</p>
                  </v-col>
                  <v-col cols="12">
                    <div class="text--primary">Detalle: {{ item.detalle }}</div>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-card-actions>
                <v-btn dark @click="agendar(item)" color="deep-purple accent-4">
                  Programación
                </v-btn>
                <v-btn outlined small @click="modificar(item)" color="green">
                  Modificar
                </v-btn>
                <v-btn outlined small @click="eliminar(item)" color="red">
                  Eliminar
                </v-btn>
              </v-card-actions>
            </v-card>
          </template>
        </v-list>
      </div>
      <DModal
        :titulo="(esNuevo ? 'Agregar' : 'Editar') + ' Servicio'"
        v-model="dialogo"
        ocutarFooter
        persistent
        @cerrar="dialogo = false"
      >
        <Creareditarservicios
          :item="itemSelect"
          :esNuevo="esNuevo"
          v-if="dialogo"
          @cerrar="dialogo = false"
        />
      </DModal>
      <DModal
        titulo="Calendario"
        v-model="dialogoCalendario"
        ocutarFooter
        persistent
        fullscreen
        @cerrar="dialogoCalendario = false"
      >
        <ListaCalendario
          :item="itemSelect"
          v-if="dialogoCalendario"
          @cerrar="dialogoCalendario = false"
        />
      </DModal>
      <div>
        <v-dialog v-model="dialogEliminar" persistent max-width="290">
          <v-card>
            <v-card-title class="text-h5"> Eliminar Servicio </v-card-title>
            <v-card-text
              >El servicio se eliminara de forma permanente de la
              lista</v-card-text
            >
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="green darken-1"
                text
                @click="dialogEliminar = false"
              >
                Cancelar
              </v-btn>
              <v-btn color="red darken-1" text @click="eliminarRegistro()">
                Eliminar
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </div>
    </DContenidoPagina>
  </div>
</template>

<script>
import { DBotonSimple, DContenidoPagina, DModal } from "../../components";
import Creareditarservicios from "./servicios/creareditarservicios.vue";
import ListaCalendario from "./calendario/listaCalendario.vue";
import app from "../../firebase";
import {
  getDatabase,
  ref,
  remove,
  onChildChanged,
  onChildAdded,
  onChildRemoved,
} from "firebase/database";
export default {
  components: {
    DBotonSimple,
    DContenidoPagina,
    DModal,
    Creareditarservicios,
    ListaCalendario,
  },
  data() {
    return {
      dialogo: false,
      dialogoCalendario: false,
      dialogEliminar: false,
      esNuevo: true,
      servicios: [],
      itemSelect: {},
      nuevoServicio: {
        tipo: "",
        descripcion: "",
        tiempo: "",
      },
    };
  },
  mounted() {
    //const recentPostsRef = query(ref(serviciosDB, 'servicios'), limitToLast(100));
    const db = getDatabase(app);
    const commentsRef = ref(db, "servicios");
    onChildAdded(commentsRef, (data) => {
      this.servicios.push({
        key: data.key,
        titulo: data.val().titulo,
        descripcion: data.val().descripcion,
        detalle: data.val().detalle,
        pasos: data.val().pasos,
      });
    });
    onChildChanged(commentsRef, (data) => {
      for (let i = 0; i < this.servicios.length; i++) {
        if (this.servicios[i].key == data.key) {
          this.servicios[i].titulo = data.val().titulo;
          this.servicios[i].descripcion = data.val().descripcion;
          this.servicios[i].detalle = data.val().detalle;
          this.servicios[i].pasos = data.val().pasos;
        }
      }
    });

    onChildRemoved(commentsRef, (data) => {
      for (let i = 0; i < this.servicios.length; i++) {
        if (this.servicios[i].key == data.key) {
          this.servicios.splice(i, 1);
        }
      }
    });
  },
  methods: {
    agendar(item) {
      this.itemSelect = item;
      this.dialogoCalendario = true;
    },
    crearServicio() {
      this.esNuevo = true;
      this.itemSelect = {};
      this.dialogo = true;
    },
    modificar(item) {
      this.esNuevo = false;
      this.itemSelect = item;
      this.dialogo = true;
    },
    eliminar(item) {
      this.itemSelect = item;
      this.dialogEliminar = true;
    },
    eliminarRegistro() {
      const db = getDatabase(app);
      remove(ref(db, "servicios/" + this.itemSelect.key));
      this.dialogEliminar = false;
      this.$store.commit("alert", {
        color: "success",
        text: "Se elimino de forma correcta",
      });
    },
    obtenerImagen(){
        return "~@/assets/img/servicios/mecanica.png";
    }
  },
};
</script>

<style></style>
