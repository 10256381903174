<template>
  <div>
    <div>
      <v-form ref="form" lazy-validation>
        <v-row no-gutters>
          <v-col cols="12" sm="12">
            <v-text-field
              label="Titulo"
              maxlength="50"
              :counter="50"
              autocomplete="off"
              outlined
              placeholder="Ej. Mecánica"
              v-model="nuevoServicio.titulo"
              :rules="[
                (v) => !!v || 'Titulo es requerido',
                (v) =>
                  (v && v.length >= 5 && v.length <= 50) ||
                  'el titulo debe tener entre 5 y 50 caracteres',
              ]"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="12">
            <v-text-field
              label="Descripción"
              maxlength="50"
              :counter="50"
              autocomplete="off"
              outlined
              placeholder="Ej. Servicios de reparaciones de motor y otros"
              v-model="nuevoServicio.descripcion"
              :rules="[
                (v) => !!v || 'Descripción es requerido',
                (v) =>
                  (v && v.length >= 5 && v.length <= 50) ||
                  'La Descripción debe tener entre 5 y 50 caracteres',
              ]"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="12">
            <v-textarea
              outlined
              label="Detalle de Servicios"
              placeholder="Ej. Se realizan cambios de motor, caja, etc."
              v-model="nuevoServicio.detalle"
              :rules="[
                (v) => !!v || 'Detalle es requerido',
                (v) =>
                  (v && v.length >= 5 && v.length <= 200) ||
                  'Detalle debe tener entre 5 y 200 caracteres',
              ]"
            ></v-textarea>
          </v-col>
          <v-col cols="12" sm="12">
            <v-text-field
              label="Numero de pasos para concluir con el servicio"
              maxlength="30"
              :counter="30"
              autocomplete="off"
              outlined
              type="number"
              v-model="nuevoServicio.pasos"
              :rules="[
                (v) => !!v || 'Los pasos son requeridos',
                (v) =>
                  (v && v >= 1 && v <= 10) ||
                  'Los pasos deben ser de 1 y 10',
              ]"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-form>

      <v-btn
        type="submit"
        @click="agregarServicio"
        color="primary"
        class="pl-4 pr-4"
        :disabled="cargando"
        >Guardar
        <v-progress-circular
          v-if="cargando"
          indeterminate
          color="primary"
        ></v-progress-circular>
      </v-btn>
    </div>
  </div>
</template>

<script>
import app from "../../../firebase";
import { getDatabase, ref, push, set } from "firebase/database";
export default {
  props: ["item", "esNuevo"],
  data() {
    return {
      cargando: false,
      nuevoServicio: {
        titulo: "",
        descripcion: "",
        detalle: "",
        pasos: 0,
      },
    };
  },
  mounted() {
    if (!this.esNuevo) {
      this.nuevoServicio.titulo = this.item.titulo;
      this.nuevoServicio.descripcion = this.item.descripcion;
      this.nuevoServicio.detalle = this.item.detalle;
      this.nuevoServicio.pasos = this.item.pasos;
    }
  },
  methods: {
    agregarServicio() {
      if (!this.$refs.form.validate()) {
        return;
      }
      const db = getDatabase(app);
      this.nuevoServicio.pasos=parseInt(this.nuevoServicio.pasos);
      if (this.esNuevo) {
        this.cargando = true;
        const postListRef = ref(db, "servicios");
        const newPostRef = push(postListRef);
        set(newPostRef, this.nuevoServicio);
        this.$store.commit("alert", {
          color: "success",
          text: "Se registro de forma correcta",
        });
      } else {
        set(ref(db, "servicios/" + this.item.key), this.nuevoServicio);
        this.$store.commit("alert", {
          color: "success",
          text: "Se modifico de forma correcta",
        });
      }
      this.cargando = false;
      this.$emit("cerrar");
    },
  },
};
</script>

<style></style>
